<template>
    <v-row class="noprint">
        <v-col cols="12" class="text-center mt-10">
            <h1 class="title">
                <img src="favicon.png" class="mt-3" height="50"/><br>
                <span class="display-1 font-weight-bold">
                    MYSCH TEACHPLAN
                </span>
            </h1>
            <p class="subheading font-weight-regular">
                Generate Lesson Notes and Plans in Seconds
            </p>
            
            <v-alert dense dismissible type="info" color="#f0b23e" class="black--white"><small>In this version, all data you generate is stored on your device. Be sure to use a device you own</small></v-alert>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'AppHeader',
    data: () => ({
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        stats: [
            { title: 'Students this Term', icon: 'mdi-account', count: 14 },
        ],
        record:{
            email:null,
            password:null,
        },
    }),
}
</script>