<template>
  <v-app>
    <v-app-bar
      app
      color="#963234"
      class="noprint white--text"
      :collapse="true"
    >

        <!-- <v-app-bar-nav-icon class="hovp" color="#e6205a" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
        <!-- <div class="d-flex align-center text-uppercase">
            <v-img
            alt="Logo"
            class="shrink mr-2"
            contain
            src="/favicon.png"
            transition="scale-transition"
            width="40"
            />
        </div> -->

        <!-- <router-link v-if="user && !user.email_verified_at && emailVerified == false" to="verify-email" class="nav-link">Verify Your Email</router-link> -->

        <v-spacer></v-spacer>
      <v-btn
        text
        color="#e6205a"
      >
        <span class="mr-2 caption white--text">v1.0.0</span>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      width="300"
      dark
      class="white--text noprint"
    >
      <guest-menu-card v-if="isLoggedIn == false && !school"/>
      <auth-menu-card v-else/>
    </v-navigation-drawer>
    <app-header/>
    <v-main>
      <router-view/>
    </v-main>
    <app-footer/>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import AppFooter from './components/AppFooter.vue';
import AppHeader from './components/AppHeader.vue';
import AuthMenuCard from './components/AuthMenuCard.vue';
import GuestMenuCard from './components/GuestMenuCard.vue';

export default {
    components: { AppFooter, AuthMenuCard, GuestMenuCard, AppHeader },
    name: 'App',

    data: () => ({
        drawer: false,
        group: null
    }),
    computed: {
        ...mapState({
        isLoggedIn: (state) => state.isLoggedIn,
        emailVerified: (state) => state.emailVerified,
        }),
        school (){
            if (this.isLoggedIn == true) {
                return JSON.parse(localStorage.getItem('school'))
            }
            return JSON.parse(localStorage.getItem('school'))
        },
        user (){
            if (this.isLoggedIn == true) {
                return JSON.parse(sessionStorage.getItem('loggedUser'))
            }
            return JSON.parse(sessionStorage.getItem('loggedUser'))
        }
    },
    watch: {
        group () {
            this.drawer = false
        }
    },
    methods:{
    }
};
</script>

<style>
.v-card{
    box-shadow: #000;
}
</style>

<style scoped>
.nav-link{
    text-decoration: none;
    color: #000;
    background: rgb(255, 202, 242);
    padding: 3px 10px;
    border-radius: 10px;
}
.v-main{
    padding-top: 5px !important
}
</style>
