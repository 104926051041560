<template>
    <div>
        <v-card-title class="caption">USER MENU 
            <!-- <v-btn color="primary" x-small text @click="showMenu = !showMenu"><v-icon>mdi-menu-swap</v-icon>Hide/Show Menu</v-btn> -->
        </v-card-title>
        <v-card-text>
            <v-row v-show="showMenu">
                <v-col cols="12" v-for="(item, i) in items" :key="i">
                    <router-link :to="item.link" class="nav-link">
                        <v-icon>{{item.icon}}</v-icon>
                        <span class="ml-3 mr-3">{{item.text}}</span>
                    </router-link>
                </v-col>
                <v-col cols="12">
                    <a class="nav-link" @click="signout">
                        <v-icon>mdi-minus-circle-outline</v-icon>
                        <span class="ml-3 mr-3">Signout</span>
                    </a>
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script>
  export default {
    name: 'UserMenuCard',
    data: () => ({
        updateSchoolModal: false,
        showMenu: true,
        items: [
            { text: 'All Lesson Plans', icon: 'mdi-file-document-outline', link: 'dashboard' },
            { text: 'All Lesson Notes', icon: 'mdi-typewriter', link: 'scores' },
        ],
    }),
    methods: {
        signout () {
            sessionStorage.removeItem('authToken')
            localStorage.removeItem('school')
            sessionStorage.removeItem('loggedUser')
            this.$store.commit("isLoggedIn", false);
            this.$router.push('login')
        }
    }
  }
</script>

<style scoped>
.nav-link{
    text-decoration: none;
    color: #a5a084 !important;;
}
.router-link-active{
    color: rgb(241, 255, 181) !important;
    padding: 5px;
    font-weight: 700;
}
</style>
