<template>
  <v-container>
    <v-row>
        <v-col cols="12" class="noprint">
            <v-card>
                <v-card-title>My Lesson Notes/Plans</v-card-title>
                <v-card-subtitle class="caption red--text">Here you will find all your created lesson notes and plans</v-card-subtitle>
                <v-card-text>
                    <ul v-for="(record,i) in records" :key="i">
                        <li class="mb-3">{{record.topic}} | 
                            <small>{{record.subject}} | {{record.class_name}} | Week {{record.week}}</small> 
                            <v-btn @click="editRecord(record)" x-small fab class="ml-3" title="update"> <v-icon color="blue">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn @click="viewPreview(record)" x-small fab class="ml-1" title="view and print"> <v-icon color="purple">mdi-printer</v-icon>
                            </v-btn>
                            <v-btn @click="deleteRecord(i)" x-small fab class="ml-1" title="delete"> <v-icon color="red">mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </li>
                    </ul>
                    <v-btn @click="resetForm" color="#f0b23e" small class="mt-5"> Create New </v-btn>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" class="text-center noprint" v-if="showForm == true || showPreview == false">
            <v-stepper v-model="next">
                <v-stepper-content step="1">
                <v-card
                    color="#fefefe"
                    class="mb-12"
                    elevation="0"
                >
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="School Name"
                                    outlined dense clearable
                                    v-model="record.school_name"
                                    hint="Once entered, we will save and remember it for you!"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Teacher Name"
                                    outlined dense clearable
                                    v-model="record.teacher_name"
                                    hint="Once entered, we will save and remember it for you!"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Class"
                                    outlined dense clearable
                                    v-model="record.class_name"
                                    hint="Type the class you are preparing this plan/note for!"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Week"
                                    outlined dense clearable
                                    v-model="record.week"
                                    hint="Just enter a number like Week 7, just type 7"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Subject *"
                                    outlined dense clearable
                                    v-model="record.subject"
                                    hint="If you do not enter a SUBJECT the form wont save!"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-btn
                    color="primary"
                    @click="storeProgress"
                >
                    Next
                </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                <v-card
                    color="#fefefe"
                    class="mb-12"
                    elevation="0"
                >
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <p>Lesson Plan/Note by: <strong>{{record.teacher_name}}</strong>, for Class <strong>{{record.class_name}}</strong></p>
                                <p>Subject: <strong>{{record.subject}}</strong>, Week <strong>{{record.week}}</strong></p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Topic *"
                                    outlined dense clearable
                                    v-model="record.topic"
                                    hint="If you do not enter a TOPIC the form wont save!"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Sub-topic"
                                    outlined dense clearable
                                    v-model="record.subtopic"
                                    hint="This is optional and can be skipped"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Reference Material"
                                    outlined dense clearable
                                    v-model="record.reference_material"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Instructional Material"
                                    outlined dense clearable
                                    v-model="record.instructional_material"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-btn
                    color="primary"
                    @click="storeProgress"
                >
                    Next
                </v-btn>
                <v-btn text @click="next = 1">
                    Back
                </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                <v-card
                    color="#fefefe"
                    class="mb-12"
                    elevation="0"
                >
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <p>Lesson Plan/Note by: <strong>{{record.teacher_name}}</strong>, for Class <strong>{{record.class_name}}</strong></p>
                                <p>Subject: <strong>{{record.subject}}</strong>, Week <strong>{{record.week}}</strong>, Topic <strong>{{record.topic}}</strong></p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Objectives"
                                    outlined dense clearable
                                    v-model="record.objectives"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Instructions"
                                    outlined dense clearable
                                    v-model="record.instructions"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Lesson Content"
                                    outlined dense clearable
                                    hint="Enter lesson content here if you need lesson note together with lesson plan"
                                    v-model="record.lesson_content"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Body of Topic"
                                    outlined dense clearable
                                    v-model="record.topic_body"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-file-input
                                    counter
                                    show-size chip
                                    truncate-length="50"
                                    placeholder="Click to select one or more images"
                                    accept="image/png, image/jpeg, image/jpg"
                                    v-model="record.files"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-btn
                    color="primary"
                    @click="storeProgress"
                >
                    Next
                </v-btn>
                <v-btn text @click="next = 2">
                    Back
                </v-btn>
                </v-stepper-content>

                <v-stepper-content step="4">
                <v-card
                    color="#fefefe"
                    class="mb-12"
                    elevation="0"
                >
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <p>Lesson Plan/Note by: <strong>{{record.teacher_name}}</strong>, for Class <strong>{{record.class_name}}</strong></p>
                                <p>Subject: <strong>{{record.subject}}</strong>, Week <strong>{{record.week}}</strong>, Topic <strong>{{record.topic}}</strong></p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Evaluation"
                                    outlined dense clearable
                                    v-model="record.evaluation"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Questions"
                                    outlined dense clearable
                                    v-model="record.questions"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-textarea
                                    label="Conclusion"
                                    outlined dense clearable
                                    v-model="record.conclusion"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-btn
                    color="primary"
                    @click="updatetoArray"
                    v-if="editing"
                >
                    Update &amp; Preview
                </v-btn>
                 <v-btn
                    color="primary"
                    @click="storetoArray"
                    v-else
                >
                    Save &amp; Preview
                </v-btn>
                <v-btn text @click="next = 3">
                    Back
                </v-btn>
                </v-stepper-content>
            </v-stepper>
        </v-col>
        <v-col cols="12" v-else>
            <v-card v-if="records.length > 0">
                <v-card-text class="#f5f5f5">
                    <h1 class="text-uppercase text-center">{{record.school_name}}</h1>
                </v-card-text>
                <v-card-text>
                    <h3 class="text-center blue--text">LESSON PLAN</h3>
                    <p class="text-center"><em>Prepared By</em> <strong>{{record.teacher_name}}</strong></p>
                    <table>
                        <tbody>
                            <tr>
                                <td>SUBJECT</td>
                                <td colspan="3">{{record.subject}}</td>
                            </tr>
                            <tr>
                                <td>CLASS</td>
                                <td>{{record.class_name}}</td>
                            </tr>
                            <tr>
                                <td>WEEK</td>
                                <td>{{record.week}}</td>
                            </tr>
                            <tr>
                                <td>Topic</td>
                                <td colspan="3">{{record.topic}}</td>
                            </tr>
                            <tr v-if="record.subtopic">
                                <td>Sub-topic</td>
                                <td>{{record.subtopic}}</td>
                            </tr>
                            <tr v-if="record.reference_material">
                                <td>Reference Material</td>
                                <td>{{record.reference_material}}</td>
                            </tr>
                            <tr v-if="record.instructional_material">
                                <td>Instructional Material</td>
                                <td>{{record.instructional_material}}</td>
                            </tr>
                            <tr v-if="record.objectives">
                                <td>Objectives</td>
                                <td>{{record.objectives}}</td>
                            </tr>
                            <tr v-if="record.instructions">
                                <td>Instructions</td>
                                <td>{{record.instructions}}</td>
                            </tr>
                            <tr v-if="record.topic_body">
                                <td>Body of Topic</td>
                                <td>{{record.topic_body}}</td>
                            </tr>
                            <tr v-if="record.evaluation">
                                <td>Evaluation</td>
                                <td>{{record.evaluation}}</td>
                            </tr>
                            <tr v-if="record.questions">
                                <td>Questions</td>
                                <td>{{record.questions}}</td>
                            </tr>
                            <tr v-if="record.conclusion">
                                <td>Conclusion</td>
                                <td>{{record.conclusion}}</td>
                            </tr>
                        </tbody>
                    </table>
                </v-card-text>
                <hr/>
                <v-card-text>
                    <h3 class="blue--text text-center">LESSON NOTE</h3>
                    <small class="text-center mb-3">Content:</small>
                    <p>{{record.lesson_content}}</p>
                    <p v-if="record.files"><img :src="record.files" width="150px" class="d-inline"/></p>
                    <v-btn
                        fab
                        color="yellow"
                        @click="printPreview(record)"
                        class="noprint"
                        >
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                </v-card-text>
                <v-card-text class="d-none d-print-block">
                    <p class="text-center"><small>Printed on {{new Date()}}</small><br>
                    <small>Printed from https://teachplan.mysch.ng</small></p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { uuid } from 'vue-uuid';
  export default {
    name: 'Home',
    data () {
      return {
        next: 1,
        record: localStorage.getItem('mys_tcpl_record') ? JSON.parse(localStorage.getItem('mys_tcpl_record')) : {},
        records: localStorage.getItem('mys_tcpl_records') ? JSON.parse(localStorage.getItem('mys_tcpl_records')) : [],
        showForm: false,
        showPreview: true,
        editing: false,
        uuid: uuid.v4()
      }
    },
    methods:{
        storeProgress(){
            localStorage.mys_tcpl_record =  JSON.stringify(this.record)
            this.next = this.next + 1
        },
        storetoArray(){
            this.fetchImage()
            this.record.id = this.uuid
            localStorage.mys_tcpl_record =  JSON.stringify(this.record)
            if(this.record.topic && this.record.subject){
                this.records.push(this.record)
                localStorage.mys_tcpl_records = JSON.stringify(this.records)
                this.viewPreview(this.record)
            }
        },
        editRecord(record){
           this.record = record
           this.showForm = true
           this.editing = true
           this.next = 1
        },
        updatetoArray(){
            this.fetchImage()
            localStorage.mys_tcpl_record =  JSON.stringify(this.record)
            if(this.record.topic && this.record.subject){
                localStorage.mys_tcpl_records = JSON.stringify(this.records)
                this.viewPreview(this.record)
            }
        },
        deleteRecord(position){
            if (confirm("This record will be deleted from your records. Do you want to proceed?")){
                this.records.splice(position, 1)
                localStorage.mys_tcpl_records = JSON.stringify(this.records)
            }
        },
        viewPreview(record){
           this.record = record
           this.showPreview = true
           localStorage.removeItem('mys_tcpl_record')
           this.showForm = false
        },
        printPreview(){
            window.print()
        },
        resetForm(){
            if (this.records.length > 0) {
                if (confirm("This will clear any entry you have made in the form and start a fresh form for you to make another Lesson note / plan. Do you want to proceed?")){
                    this.resetRecord()
                    localStorage.removeItem('mys_tcpl_record')
                }
            }
            this.showForm = true
            this.editing = false
            this.next = 1
        },
        resetRecord(){
            this.record = {
                school_name:this.record.school_name ?? this.records[0].school_name,
                teacher_name:this.record.teacher_name ?? this.records[0].teacher_name,
                class_name:this.record.class_name ?? this.records[0].class_name,
                week:this.record.week ?? this.records[0].week,
            }
        },
        fetchImage(){
            if (this.record.files) {
                this.record.files = URL.createObjectURL(this.record.files)
            }
        }
    }
  }
</script>

<style>
table{
    border-collapse: collapse;
    width: 100%;
}
table tr > td, table tr > th{
    border-top: 1px solid rgb(190, 190, 190) !important; 
    border-bottom: 1px solid rgb(190, 190, 190)  !important; 
    padding: 0 4px 0 4px;
}
table tbody tr td{
    font-size: 12px;
}
tr td:first-child{
    font-weight: 700;
}
td{
    white-space: pre;
}
@media print{
    .noprint{
        display: none !important
    }
}
</style>
